import SensitivityTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/sensitivity/SensitivityTemplate';

import { AMOUNT } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Redness/Sensitivity/SensitivityTemplate'
};

const createStory = props => () => ({
  components: { SensitivityTemplate },
  storyProps: props,
  wrapStyles: {
    margin: '20px auto',
    width: '360px',
    maxWidth: '100%'
  },
  template: `<div :style='$options.wrapStyles'>
    <sensitivity-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  sensitiveSkin: AMOUNT.MODERATELY
});

<template>
  <question-with-error-wrap :error="getFieldsError(['sensitiveSkin'])">
    <single-answer-question
      title="label.sensitiveSkin"
      hint="info.sensitiveSkin"
      :value="sensitiveSkin"
      :options="$options.responseOptions"
      dysfunction="skin-redness"
      @input="onFieldChange('sensitiveSkin', $event)"
    />
  </question-with-error-wrap>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { AMOUNT_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'SensitivityTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  responseOptions: AMOUNT_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    sensitiveSkin: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
